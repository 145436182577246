.lgn {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.lgn-root {
  display: flex;
  justify-content: center;
  align-items: center;

  height: 100vh;
  width: 100%;
}

.lgn-section {
  display: flex;
  flex-direction: column;

  height: 100%;
  width: 50%;
}

.lgn-section.anim {
  background: #F5F5FD;
}

.lgn-section .form {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  height: 100%;
  width: 100%;
  padding: 0 10em;
}

.lgn-section .title {
  color: #1f4788;
  text-shadow: 0px 4px 8px rgba(14, 77, 164, 0.08),
    0px 12px 32px rgba(14, 77, 164, 0.08), 0px 4px 24px rgba(14, 77, 164, 0.04);
  font-weight: 600;
  font-size: 2rem;
}

.lgn-section .input {
  background: #F5F5FD;
  border-radius: 8px;
  padding: 8px 1em;
}

.lgn-section textarea:focus,
.lgn-section input:focus,
.lgn-section button:focus {
outline: none;
}

.lgn-section .caption {
  text-align: justify;
}

.lgn-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.lgn-footer .caption {
  padding: 2em 5em;
}

.lgn .button {
  background: #1F4788;
  color: #ffffff;
  border-style: none;

  border-radius: 8px;
  padding: 8px 30px;
}

.button-disable{
  background: #CCC;
  color: #000;
  border-style: none;

  border-radius: 8px;
  padding: 8px 30px;
}

.button-process{
  border-style: none;
  background: #f1efef url("../../images/Pulse-1s-51px.gif") no-repeat center;
  border-radius: 8px;
  padding: 8px 30px;
  margin-bottom: 40px;
  background-size: 30px;
  border: 1px solid #CCC;
}

.circle-image {
  width: 53px;
  height: 53px;
  background: #E9ECF1;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
}