@font-face {
    font-family: regularFont;
    src: url(../font/Poppins-Regular.ttf);
}

*{
    padding: 0px;
    margin: 0px;
    font-family: regularFont;
}

body{
    background: #F9FAFC; 
}

a {
    text-decoration: none;
    cursor: pointer;
}

.sb-menu {
    cursor: pointer;
    background: #FFF;
    color: #717171 !important;
}

.sb-menu:hover{
    cursor: pointer;
    background: #E9EDF4;
    color: #1F4788 !important;
}

.react-calendar__month-view__weekdays{
    font-size: 12px !important;
    font-weight: bold;
    /* margin-top: -20px; */
    /* border-bottom: 1px solid #CCC; */
    /* border-top: 1px solid #CCC; */
    background: #000;
    color: #FFF;
}

.bs-frm-ip{
    display: flex;
    align-items: center;
}

abbr{
    text-decoration: none !important;
}

.rpt-calendar{
    border-radius: 5px;
    margin: auto;
    border: 1px solid #e1e1e1 !important;
    width: 100% !important;
}

.bs-bs-frm-ip{
    width: 50%;
    padding: 20px 20px;
}

.frm-input {
    background: #FFFFFF;
    border: 1px solid #1F4788;
    box-sizing: border-box;
    border-radius: 6px;
    height: 46px;
    font-size: 14px;
    outline: none;
    padding: 0px 20px;
    width: 100%;
}

.ttl-ip{
    color: #333333;
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 20px;
}

.textarea-frm-input {
    padding: 20px;
}

.input-bs-border {
    border: 1px solid #D2D2D2 !important;
}

.main-border-left {
    border-left: 1px solid #CCC;
}

.frm-input-mbl{
    height: 41px;
    border: 1px solid #949494;
    border-radius: 10px;
    outline: none;
    padding: 0px 12px;
    box-sizing: border-box;
}

.button{
    background: #1F4788;
    color: #ffffff;
    border-style: none;

    border-radius: 8px;
    padding: 8px 30px;
}

.bs-btn{
    text-align: right;
    margin-top: 80px;
}

.btn-primary {
    background: #1F4788;
    color: #FFF;
    justify-content: center;
    align-items: center;
    display: flex;
    outline: none;
    border: none;
}

.btn-gray {
    background: #e5e5e5;
    color: #000;
    border: 1px solid #CCC;
    justify-content: center;
    align-items: center;
    display: flex;
    outline: none;
}

.btn-sv {
    width: 281px;
    height: 49px;
    background: #46BD84;
    border-radius: 8px;
    color: #FFF;
    border: none;
    outline: none;
    margin: 0px 20px;
}

table {
    border-collapse: collapse;
}

th{
    background: #F4F7FC;
    border: 1px solid #C8DDEA;
    padding: 18px;
    color: #1F4788;
    font-size: 16px;
    line-height: 19px;
    font-weight: normal;
}

select{
    outline: none;
}

tr{
    cursor: pointer;
}

.select:hover td{
    background: #e5e5e5;
}

td{
    border: 1px solid #C8DDEA;
    background: #FFF;
    padding: 15px;
    font-size: 14px;
    color: #333333;
}

.pic-dt-td{
    width: 24px;
    height: 24px;
    position: relative;
    background: #08A0F7;
    border-radius: 100%;
    margin-right: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #FFF;
    font-size: 10px;
}

button{
    cursor: pointer;
    outline: none;
}

button:hover{
    opacity: 0.8;
}

.img-pgn{
    width: 46px;
    height: 36px;
    background: #F4F7FC;
    border: 1px solid #C8DDEA;
}

#ft-tbl-dir{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.bs-sts {
    width: 92px;
    height: 28px;
    background: rgba(107, 201, 255, 0.2);
    border-radius: 200px;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
}

.bs-sts-tr {
    width: 92px;
    height: 28px;
    background: rgba(129, 255, 211, 0.2);
    border-radius: 200px;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
}

.bs-act-dsh{
    display: flex;
    width: 100px;
    align-items: center;
    justify-content: flex-end;
    margin: auto;
}

.clr-act{
    color: #34A770;
}

.clr-rjt{
    color: #F85640;
}

@media only screen and (min-width: 1202px) {
    #bs-content{
        margin-left: 222px;
    }
}

@media only screen and (max-width: 1202px) {
    #bs-content{
        margin-left: 0px;
    }
}

.MuiPickersToolbar-toolbar, .MuiPickersDay-daySelected{
    background: #333333 !important;
}

#date-picker{
    font-size: 14px;
}

#date-picker.parent {
    background: #08A0F7;
}

#time-picker{
    font-size: 14px;
}

.item-slc{
    background: "fff";
}

.item-slc:hover{
    background: #C8DDEA;
}

.shadow{
    box-shadow: 2px 2px 1px #ebe9e9
}

.select:hover{
    background: #e5e5e5;
}

.menu-hvr:hover .menu-hvr-item{
    display: block;
}

.block-popup{
    width: 100%;
    height: 100%;
    z-index: 1000;
    top: 0;
    left: 0;
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(0,0,0,0.4)
}

.menu-hvr-item{
    display: none;
}

.menu-hvr-item-item{
    cursor: pointer;
}

.menu-hvr-item-item:hover{
    background: #e9f3f8;
}